.login-page, .register-page {
    background-color: #ffffff;
    display: block;
    height: 100vh;
}

// .container-login{
//     height: 100vh;
// }

.height-login{
    height: 70vh;
    max-width:fit-content;
    margin: auto;
    margin-bottom: 8rem;
}


.card {
    background-color: #fff;
    border:solid;
    border-radius: 10px;
    border-width: 1px;
    color: #0275D8;
    padding: 10px;
    display: block;
    position: inherit;
    min-width: 430px;
}

.register-layout-box .card{
        background-color: #fff;
        border:solid;
        border-radius: 10px;
        border-width: 1px;
        color: #0275D8;
        display: block;
        position: inherit;
        min-width: 900px;
        min-width: 880px;
    
}


@media(max-width:991px){
    .register-layout-box .card{
        background-color: #fff;
        border:solid;
        border-radius: 10px;
        border-width: 1px;
        color: #0275D8;
        padding: 20px;
        display: block;
        position: inherit;
        min-width: 350px;
        max-width:500px;
    

    }
    .card {
        background-color: #fff;
        border:solid;
        border-radius: 10px;
        border-width: 1px;
        color: #0275D8;
        padding: 20px;
        display: block;
        position: inherit;
        min-width: 300px;
    }
    
}

.form-control{
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #707070;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #0275D8;
    border-radius: 10px;
    box-shadow:none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 45px;
}

.login-card-body .input-group .input-group-text, .register-card-body .input-group .input-group-text {
    background-color: transparent;
    border-bottom-right-radius: 0.5rem;
    border-left: 0;
    border-top-right-radius: 0.5rem;
    color: #707070;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-text {
    background-color: transparent;
    border-left: 0;
    border: 2px solid #0275D8;
    border-radius:10px;
    color: #707070;

  }


.account-sidebar {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (min-width:1199px) {
    .account-sidebar {
        top: 0;
        left: 0;
        bottom: 0;
        height: auto;
        background: linear-gradient(0deg, #00EBFF 0%, #00E0FF 7.7%, #00A8FF 50.8%, #0085FF 82.8%, #0078FF 100%);
    }
}
@media (max-width:1199px){
    .height-login{
        height: auto;
    }
    
}
