@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~handsontable/dist/handsontable.full.css';
@import 'login-register.scss';

// FONT
@font-face {
    font-family: 'Museo';
    src: url('/assets/font/MuseoRegular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Museo';
    src: url('/assets/font/MuseoLight.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: 'Museo';
    src: url('/assets/font/MuseoBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}

body {
    font-family: 'Museo';
    color:#4d4d4d!important;
}

body:not(.layout-fixed) .main-sidebar {
    position: absolute;
    top: 0;
    height: 100vh !important;
}

p,
span {
    font-size: 1.125rem;
}

@media (min-width: 1400px) {
    .col-xxl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

col-xxl-2,
col-xxl-3,
col-xxl-4,
.col-xxl-6 {
    position: relative;
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
}

.wrapper .content-wrapper {
    min-height: calc(100vh - calc(4.4rem + 1px) - calc(3.5rem + 1px));
}

// SIDEBAR
.nav-user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2rem;
    margin-top: -4px;
    width: 2rem;
}

.navbar-light .navbar-nav .nav-link.burgerGradient {
    color: #0085ff;
    font-size: 30px;
    background-size: cover;
    padding: 0 !important;
}

.main-sidebar {
    background: linear-gradient(0deg, #00ebff 0%, #00e0ff 7.7%, #00a8ff 50.8%, #0085ff 82.8%, #0078ff 100%);
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active,
.nav-pills .nav-link:not(.active):hover {
    background-color:  #0000ff!important;
    color: #fff !important;
    width: 25rem;
}

.nav-sidebar > .nav-item {
    margin-bottom: 0.5rem;
    background-color: #0275d8;
}

.nav-sidebar > .nav-item > a {
    color: white;
}

.nav-sidebar > .nav-item > a,
.nav-sidebar > .nav-item > a > p {
    font-size: 1.25rem;
}

.sidebar-mini.sidebar-open .brand-link .brand-image.image-open {
    float: left;
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: 10px;
    padding-left: 0.8rem;
    max-height: 80px !important;
    width: auto;
    display: inline;
}

.sidebar-mini.sidebar-open .brand-link .brand-image.image-collapse {
    float: left;
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: 10px;
    padding-left: 0.8rem;
    max-height: 80px !important;
    width: auto;
    display: none;
}

.sidebar-mini.sidebar-collapse .brand-link .brand-image.image-open {
    float: left;
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: 10px;
    padding-left: 0.8rem;
    max-height: 80px !important;
    width: auto;
    display: none;
}

.sidebar-mini.sidebar-collapse .main-sidebar .brand-link .brand-image.image-collapse {
    float: left;
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: 10px;
    max-height: 80px !important;
    width: auto;
    display: inline;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-image.image-open {
    float: left;
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: 10px;
    padding-left: 0.8rem;
    max-height: 80px !important;
    width: auto;
    display: inline;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-image.image-collapse {
    float: left;
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: 10px;
    padding-left: 0.8rem;
    max-height: 80px !important;
    width: auto;
    display: none !important;
}

.sidebar-mini.sidebar-open .main-sidebar .sidebar .user-panel,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .sidebar .user-panel {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.sidebar-mini.sidebar-collapse .main-sidebar .sidebar .user-panel {
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: flex;
    align-items: center;
}

.sidebar-mini.sidebar-collapse .main-sidebar .sidebar .user-panel div.d-flex {
    display: none;
}

[class*='sidebar-dark'] .brand-link,
[class*='sidebar-dark'] .user-panel {
    border-bottom: none !important;
}

.sidebar-mini.sidebar-open .main-sidebar .user-panel img {
    height: auto;
    width: 4rem !important;
}

.sidebar-mini.sidebar-collapse .main-sidebar .user-panel img {
    height: auto;
    width: 2rem !important;
}

.user-panel,
.user-panel .info {
    white-space: normal !important;
}

@media (max-width:1999px) {
    .user-panel,
.user-panel .info {
    white-space: normal !important;
    word-break: break-all!important;
}

}
.sidebar-mini.sidebar-collapse .main-sidebar:hover .logout-open,
.sidebar-mini.sidebar-open .main-sidebar .logout-open,
.sidebar-mini.sidebar-collapse .main-sidebar .logout-collapse {
    display: block;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover .logout-collapse,
.sidebar-mini.sidebar-collapse .main-sidebar .logout-open,
.sidebar-mini.sidebar-open .main-sidebar .logout-collapse {
    display: none;
}

@media (min-width: 1200px) {
    .sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-link,
    .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .brand-link {
        width: 100%;
    }
}

.main-header {
    border-bottom: unset !important;
}

.brand-link {
    display: block;
    font-size: 1.25rem;
    line-height: 5 !important;
    padding: 0.8125rem 0.5rem;
    transition: width 0.3s ease-in-out;
    white-space: nowrap;
}

.sidebar-mini.sidebar-open .main-sidebar {
    width: 25rem !important;
}

.sidebar-mini.sidebar-collapse .main-sidebar {
    width: 4.6rem;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover {
    width: 25rem !important;
}

.sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-flat .nav-link {
    width: 25rem !important;
}

.sidebar-mini.sidebar-open .content-wrapper {
    margin-left: 27rem !important;
    margin-right: 7rem !important;
}

.sidebar-mini.sidebar-collapse .content-wrapper {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
}

.sidebar-mini.sidebar-open .main-header {
    margin-left: 27rem !important;
}

.sidebar-mini .main-sidebar .nav-flat .nav-link {
    width: 25rem !important;
}

body:not(.layout-fixed) .main-sidebar .sidebar {
    overflow: hidden !important;
}

@media (max-width: 1199px) {
    .sidebar-mini.sidebar-open .brand-link .brand-image.image-open {
        float: left;
        line-height: 0.8;
        margin-left: 0.8rem;
        margin-right: 0.5rem;
        margin-top: -3px;
        width: 160px !important;
        height: auto;
        display: inline;
    }

    .sidebar-mini.sidebar-open .brand-link .brand-image.image-collapse {
        float: left;
        line-height: 0.8;
        margin-left: 0.8rem;
        margin-right: 0.5rem;
        margin-top: -3px;
        width: 160px !important;
        height: auto;
        display: none;
    }

    .sidebar-mini.sidebar-open .main-sidebar {
        width: 18rem !important;
    }

    .sidebar-mini .main-sidebar .nav-flat .nav-link {
        width: 18rem !important;
    }

    .sidebar-mini.sidebar-collapse .main-sidebar {
        width: 4.6rem;
        margin-left: -250px !important;
    }

    .sidebar-mini.sidebar-collapse .main-sidebar {
        width: 4.6rem;
        margin-left: -250px !important;
    }

    .sidebar-mini.sidebar-open .main-header {
        margin-left: 14rem !important;
        z-index: 9999;
        background-color: transparent;
        color: white !important;
        border-bottom: none;
    }

    .sidebar-mini.sidebar-collapse .main-header {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    .sidebar-mini.sidebar-open .content-wrapper {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    .sidebar-mini.sidebar-collapse .content-wrapper {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    body:not(.layout-fixed) .main-sidebar {
        height: inherit;
        min-height: auto !important;
        position: absolute;
        top: 0;
    }

    .sidebar-mini.sidebar-open .wrapper .navbar-light .navbar-nav .nav-link.burgerGradient {
        color: white;
        background: none;
        font-size: 20px;
        background-size: cover;
        -webkit-background-clip: text;
        -webkit-text-fill-color: white;
        background-clip: text;
        text-fill-color: transparent;
        padding: 0 !important;
    }

    .sidebar-mini.sidebar-collapse .wrapper .navbar-light .navbar-nav .nav-link.burgerGradient {
        color: #0085ff;
        font-size: 25px;
        background-size: cover;
        padding: 0 !important;
    }
}

.login-logo > a > img {
    width: 330px;
}

.content-wrapper {
    background-color: #fff;
    margin-bottom: 5rem !important;
}

@media (max-width: 1199px) {
    .mobile-order > h3,
    .mobile-order > p {
        display: none;
    }

    .mobile-text > .mobile-order > h3,
    .mobile-text > .mobile-order > p {
        display: block;
        text-align: center;
    }
}

//GRANDEZZA FONT
.text-regular{
    font-size:1rem;
}

.text-big {
    font-size: 1.25rem;
}

.text-small{
    font-size:.85rem;
}

//COLORI
a {
    color: #0275d8;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    color: #0000ff;
    text-decoration: underline;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #0000ff!important;
    text-decoration: underline;
}

.text-secondary{
    color: #707070 !important;
}

a.text-secondary:hover,
a.text-secondary:focus{
    color: #0000ff!important;
}

.text-dark{
    color:#4d4d4d;
}
.text-orange {
    color: #fc8535;
}

.text-primary {
    color: #0275d8 !important;
}

.text-skyblue,
a.text-skyblue {
    color: #00ebff;
}


.text-number {
    font-size: 0.75rem;
    font-family: 'Museo';
}

.bg-primary {
    background-color: #0275d8 !important;
}

.bg-lightprimary {
    background-color: #007bff;
    opacity: 0.65;
    color: white;
}

.border-img {
    border-radius: 10px !important;
}

.border-primary {
    border: 1px solid #0275d8 !important;
    border-radius: 10px;
}

.border-bottom-primary {
    border-bottom: 1px solid #0275d8 !important;
}


.border-radius-default{
    border-radius: 10px;
}

.bg-warning{
    background-color:#ffffcc!important;
}

.badge-danger {
    color: #fff!important;
    background-color: #dc3545!important;
    padding: 0.5rem!important;
}

.badge-success {
    color: #fff;
    background-color: #28a745;
    padding: 0.5rem!important;
}

label {
    color: #4d4d4d !important;
    font-weight: 700 !important;
    font-size: 1rem;
}

@media (min-width: 576px) {
    .nav-user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-top: -8px;
    }
}

.form-group.required .col-form-label:after {
    color: #d00;
    content: '*';
    position: absolute;
    margin-left: 3px;
}

.form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
}

/* Sweet alert */
.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

div.wrapper,
div.content-wrapper {
    min-height: 100vh;
}

table.table {
    * {
        font-size: 14px !important;
    }

    th,
    td {
        padding: 8px;
        vertical-align: middle;
    }
}

table-card-footer * {
    font-size: 14px;
}

/* ng-select */
.ng-select .ng-select-container {
    min-height: 45px !important;
    border: 1px solid #0275d8;
    border-radius: 10px;
}

.ng-select.ng-select-opened>.ng-select-container {
    background: #fff;
    border: 2px solid #0275d8;
    border-bottom: none;
}

.ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-color: #e6e6e6;
    margin-top: -1px;
    border: 2px solid #0275d8;
    border-top:none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0,0,0,0.87);
    padding: 8px 10px;
    border-radius: 10px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.input-group-append .ng-select .ng-select-container {
    border-radius: 0 4px 4px 0 !important;
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner 0.8s linear infinite;
}

.cursor-pointer {
    cursor: pointer;
}

.ng-select.ng-select-disabled > .ng-select-container {
    background-color: #e9ecef !important;
    border-radius: 10px;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

//  PULSANTI


// .btn {
//   display: inline-block;
//   border-radius: 50px;
//   padding: 0.5rem 1rem;
//   background-color: #fff;
//   color: #0275d8;
//   font-size: 16px;
//   text-decoration: none !important;
//   transition: 0.3s;
//   border: 2px solid #0275d8;
//   min-width: 180px;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// }

.btn {
    color:#0275d8;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 2px solid #0275d8;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 50px;
    min-width: 180px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn:hover{
    background-color: #0000ff;
    color: #fff;
    border: 2px solid #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary{
    background-color: #0275d8;
    color: #fff;
}

.btn-filter,
.btn-filter:hover,
.btn-filter:active,
.btn-filter:focus,
.btn-filter:visited {
    border: 0px;
    border-radius: 10px;
    min-width: 60px;
}

.btn-admin{
    background-color: rgb(233 236 239);
    color:#0275d8;
    border: 2px solid rgb(233 236 239);
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.85rem!important;
    padding: 0.25rem 0.5rem!important;
    border-radius: 0px!important;
    min-width: inherit;
}

.btn-admin:hover{
    background-color: #969696;
    color:#fff;
    border: 2px solid #969696;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 0.85rem;
    padding: 0.25rem 0.5rem!important;
    border-radius: 0px!important;
    min-width: inherit
}
@media (max-width: 843px) {
    .btn-filter {
        min-width: 45px !important;
    }
}

.btn-sm {
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 20px;
    min-width: 60px;
}

.btn-sm:hover {
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 20px;
    min-width: 60px;
}

.btn-primary {
    color: #fff;
    background-color: #0275d8;
    border-color: #fff;
}

.btn-lesson-completed {
    display: inline-block;
    font-weight: 400;
    color: #198754;
    background-color: white;
    border: 2px solid #198754;
    font-size: 1rem;
    line-height: 2;
    min-width: 200px;
    border-radius: 50px;
}

.coopera-button {
    display: inline-block;
    border-radius: 40px 0;
    border-color: white;
    background-color: #fc8535;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff !important;
    font-size: 1.5rem;
    text-decoration: none !important;
    transition: 0.3s;
    border: solid;
    border-width: 3px;
}

.coopera-button:hover {
    background-color: #0000ff;
}

.btn-group {
    display: inline-block;
    font-weight: 400;
    color: #0275d8;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: #fff;
    border-left: none;
    border: 2px solid #0275d8;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 50px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.completed-button {
    border-color: #28a745 !important;
    border: 1px solid #28a745;
    border-radius: 50px;
    max-height: 40px;
    display: inline;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    min-width: 150px;
    text-align: center;
    margin-top: 21px;
}

page-item:last-child .page-link {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}

footer {
    width: 100%;
    background-color: #0275d8;
    color: white;
    z-index: -1;
}

.custom-control-label{
    font-weight: 400!important;
}
.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #707070 solid 1px;
    box-shadow: none;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0rem;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d4d4d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #0275d8;
    border-radius: 10px !important;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 45px;
}


.btn-group {
    display: inline-block;
    font-weight: 400;
    color: #0275d8;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: #fff;
    border-left: none;
    border: 2px solid #0275d8;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 50px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group {
    border: 1px solid #0275d8;
    border-radius: 10px;
    flex-wrap: nowrap;
}

.input-group > input.form-control,
.input-group > .input-group-append > .btn-group,
.input-group > .input-group-append > .input-group-text {
    border: none;
}

.select-card {
    border: 1px solid #0275d8 !important;
    padding: 1.2rem;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 2px solid #0275d8;
}

.ng-value {
    background-color: #0275d8 !important;
    border: 1px solid #0275d8 !important;
    border-radius: 6px !important;
    color: white !important;
    font-size: 1rem !important;
    padding: 2px 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: 1rem !important;
}

swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    width: 32em;
    max-width: 100%;
    padding: 0 0 1.25em;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
}

h2.swal2-title {
    color: #0275d8 !important;
}

.swal2-confirm.swal2-styled,
.swal2-styled.swal2-styled,
.swal2-cancel.swal2-styled {
    border: 0 !important;
    border-radius: 50px;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
    background: initial !important;
    background-color: #0275d8 !important;
    color: #fff !important;
    font-size: 1em !important;
    min-width: 150px !important;
}

.ht_clone_top.handsontable,
.ht_clone_top .wHolder,
.ht_clone_top > .wHolder > .wtHider,
.ht_clone_top > .wHolder > .wtHider > .wtSpreader,
.ht_clone_top > .wHolder > .wtHider > .wtSpreader > .htCore {
    width: 100% !important;
}

.handsontable table.htCore {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    table-layout: fixed;
    width: 0;
    outline-width: 0;
    cursor: default;
    max-width: none;
    max-height: none;
    border: 1px #0275d8 solid !important;
}

table.htCore {
    width: 100% !important;
}

.freeze-ui {
    position: fixed !important;
}

// Bandi e finanziamenti
contract-list .card {
    border: none;
    box-shadow: none;
}

// Assessment-digitale

.digital-tab ul li.nav-item a {
    border: 1px #0275d8 solid !important;
    border-radius: 10px !important;
}

.digital-tab ul li.nav-item > a.nav-link:not(.active):hover,
.digital-tab ul li.nav-item.section-completed > a.nav-link:not(.active):hover,
.digital-tab ul li.nav-item > a.nav-link.active {
    background-color: #0275d8 !important;
    color: white !important;
    width: 100%;
}

.answer-list label {
    color: #4d4d4d !important;
    font-weight: 400 !important;
}

.progress {
    box-shadow: none;
    border-radius: 10px !important;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: white;
    border: 1px #0275d8 solid !important;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0275d8;
    transition: width 0.6s ease;
    border-radius: 10px;
}

// footer

body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
.sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 0px;
}

@media (min-width: 992px) {
    .sidebar-mini.sidebar-collapse .main-footer {
        margin-left: 0px !important;
    }
}

.main-footer {
    background-color: #0275d8;
    border-top: none !important;
    color: white !important;
    padding: 1rem;
    text-align: center;
    position: absolute;
    z-index: 9999;
}


.footer-login > p {
    margin-left: 20rem;
    margin-right: 20rem;
}

@media (max-width: 1199px) {
    .footer-login {
        position: inherit;
        bottom: 0;
    }

    .footer-login > p {
        margin-left: 0;
        margin-right: 0;
    }
}

// Banner
.alert {
    position: relative;
    padding: 0 !important;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 10px !important;
}

.alert .close,
.alert .mailbox-attachment-close {
    color: #000;
    opacity: 1 !important;
}

.alert-primary {
    color: #fff;
    background-color: #0275d8 !important;
    border-color: #0275d8 !important;
}

.alert-warning {
    color: #fff !important;
    background-color: #fc8535 !important;
    border-color: #fc8535 !important;
}

//Form Group label

.section-completed {
    background-color: white !important;
}

.section-completed > .nav-link span {
    color: #28a745 !important;
}

.digital-tab ul li.nav-item.section-completed a {
    border: 1px #28a745 solid !important;
    border-radius: 10px;
}

.digital-tab ul li.nav-item.section-completed:hover a {
    border: 1px #28a745 solid !important;
    border-radius: 10px;
}

.digital-tab ul li.nav-item.section-completed:hover a.nav-link:not(.active),
.digital-tab ul li.nav-item.section-completed > a.nav-link.active {
    background-color: #28a745 !important;
}

.digital-tab ul li.nav-item.section-completed:hover .nav-link span,
.digital-tab ul li.nav-item.section-completed .nav-link.active span {
    color: white !important;
}

.maintenance-image img {
    width: 100%;
    max-width: 800px;
}

.grecaptcha-badge {
    bottom: 110px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    white-space: normal !important;
    display: flex;
}

.swal2-icon span {
    font-size: 1rem !important;
}

.container-overlay {
    position: relative;
    width: 100%;
}

.img-overlay {
    position: absolute;
    padding: 1rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background: linear-gradient(0deg, #00ebff 0%, #00e0ff 7.7%, #00a8ff 50.8%, #0085ff 82.8%, #0078ff 100%);
    z-index: 0;
}

.container-overlay:hover .img-overlay {
    opacity: 0.85;
}

.text-overlay {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 90%;
    left: 30%;
    bottom: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: left;
}

.text-block-content-type {
    bottom: 0px;
    background-color: #0275d8;
    color: white;
    padding: 0.5rem;
    display: inline-block;
    width: 50%;
}

.badge-primary{
    background-color: #0275d8;
    color: white;
    padding: 0.5rem;
    display: inline-block;
    font-size: inherit;
}
.text-block {
    position: absolute;
    bottom: 0px;
    background-color: #fffc;
    color: #0275d8;
    padding: 0.5rem;
    margin: 1rem;
}

.image-lesson {
    max-width: 200px;
    height: 100%;
    max-height: 150px;
    min-height: 150px;
    min-width: 200px;
}

.card-body .training-course-home > .image-lesson {
    max-width: 140px;
    height: 100%;
    width: 100%;
    max-height: 140px;
    min-height: 140px;
    min-width: 140px;
}

@media (max-width: 767px) {
    .image-lesson,
    .card-body .training-course-home > .image-lesson {
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 200px;
        min-height: 200px;
        margin-bottom: 1.5rem;
    }
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .blog-card,
.sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .course-card,
.sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .bandi-saved {
    height: 230px;
    min-width: 345px !important;
    padding: 0px;
    max-width: 345px !important;
}

@media (max-width: 576px) {
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .blog-card,
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .bandi-saved,
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .course-card {
        height: 212px;
        min-width: 320px !important;
        padding: 0px;
        max-width: 320px !important;
    }
}
@media (min-width: 1290px) and (max-width: 1430px) {
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .blog-card,
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .course-card,
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .bandi-saved {
        height: 234px;
        min-width: 350px !important;
        padding: 0px;
        max-width: 350px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1289px) {
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .blog-card,
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .course-card,
    .sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .bandi-saved {
        height: 200px;
        min-width: 300px !important;
        padding: 0px;
        max-width: 300px !important;
    }
}


.mySwiper .swiper-wrapper .bandi-saved .card-body h4 > a{
    font-size: 1.125rem;
}
.mySwiper .swiper-wrapper .bandi-saved .card-body span {
    font-size: 1rem;
    margin-top: 0.5rem;
}
.sidebar-collapse .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .bandi-saved .card-body .bandi-date div {
    font-size: 1rem;
    margin-top: 0.5rem;
}

.mySwiper .swiper-wrapper .bandi-saved .card-body {
    margin: 0.5rem;
}


@media (max-width: 576px) {
    .sidebar-collapse .wrapper .content-wrapper .container-fluid .mySwiper .swiper-wrapper .bandi-saved .card-body {
        margin: 0.5rem;
    }
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    bottom: 0% !important;
}
.swiper {
    width: 100%;
    height: 100%;
    min-height: 260px!important;
    max-width: 1366px;
}

.swiper-slide {
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    bottom: 0% !important;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: #0275d8 !important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 2px white;
}

.swiper-button-next:hover,
.swiper-button-prev:hover,
.swiper-button-next:focus,
.swiper-button-prev:focus {
    background-color:#0000ff!important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: 'prev';
    color: white !important;
    font-size: x-small;
    font-weight: 700;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: 'next';
    color: white !important;
    font-size: x-small;
}

.sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-button-prev {
    left: 10px !important;
    right: auto;
    opacity: 1;
    z-index: 2;
    transition: opacity 1s;
}

.sidebar-collapse .wrapper .content-wrapper .container-fluid .mySwiper .swiper-button-prev {
    left: 0px !important;
    right: auto;
    opacity: 1;
}

.sidebar-collapse .wrapper .content-wrapper .container-fluid .mySwiper .swiper-button-next {
    left: auto !important;
    right: 0px !important;
    opacity: 1;
    z-index: 2;
}

.sidebar-open .wrapper .content-wrapper .container-fluid .mySwiper .swiper-button-next {
    left: auto !important;
    right: 10px !important;
    opacity: 1;
    z-index: 2;
    transition: opacity 1s;
}


.input-group.textarea-training > textarea.form-control {
    border: none !important;
}

.sidebar-open .wrapper .content-wrapper .container-fluid .training-course-lesson-item .row div:nth-child(3) {
    max-width: 100% !important;
    flex: auto !important;
    order: 0;
}

.theme-green .bs-datepicker-head {
    background-color: #28adff !important;
}
.theme-green .bs-datepicker-body table td.week span {
    color: #0275d8 !important;
}
.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*='select-']:after,
.theme-green .bs-datepicker-body table td[class*='select-'] span:after {
    background-color: #0275d8 !important;
}

.btn-sm:hover > .text-success {
    color: white !important;
}

@media (min-width: 1199px) and (max-width: 1425px) {
    .sidebar-open .wrapper .content-wrapper .container-fluid .section-contract-filter .col-lg-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.btn-group.icon-group {
    display: inline-block;
    font-weight: 400;
    color: transparent;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: #fff;
    border-left: none;
    border: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 50px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.btn-right-0{
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
}
.nav-mobile-mode {
    background: linear-gradient(0deg, #00ebff 0%, #00e0ff 7.7%, #00a8ff 50.8%, #0085ff 82.8%, #0078ff 100%);
    width: 100%;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
    padding-top: 1rem;
}

.trainingCourseRelative{
    position: relative;
}
.GoBackAbsolute{
position: absolute;
top:0;
right:0;
}
@media(max-width:767px){
    .GoBackAbsolute{
        position: absolute;
        top:30px;
        right:0;
        }
}
